
import {defineComponent, ref} from "vue"
import DecForm from "@/modules/typeful/components/DecForm.vue"
import DecFormInput from "@/modules/typeful/components/DecFormInput.vue"
import {getFields} from "@/modules/typeful/services/FormsService"

import stationSchema from "../typeful/breedingStationSchema.json"

export default defineComponent({
  components: {
    DecFormInput,
    DecForm,
  },
  props: {
    station: {type: Object, required: true},
    isPersisted: {type: Boolean},
  },
  setup() {
    const stationFields = ref(getFields(stationSchema, {
      createFieldLabel: 'bestiary.station.field.',
    }))

    return {
      stationFields,
    }
  },
})
