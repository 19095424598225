
import {defineComponent, ref} from "vue";

import {translateMixin} from "@/i18n.ts";

import * as beastsStore from "../store/beastsStore"
import RegioFlag from "@/modules/i18n/components/RegioFlag";
import BreedingStationForm from "@/modules/bestiary/components/BreedingStationForm.vue";
import {BreedingStation} from "../model/Bestiary";

export default defineComponent({
  mixins: [
    translateMixin,
  ],
  components: {
    BreedingStationForm,
    RegioFlag,
  },
  setup() {
    const stations = beastsStore.state.breedingStations
    const stationWorkingCopy = ref<BreedingStation | null>(null)
    const stationIsPersisted = ref(false)
    const deleteId = ref(null as string|null)

    return {
      stations,

      stationWorkingCopy,
      stationIsPersisted,

      newStation: () => {
        stationWorkingCopy.value = {id: '', name: '', country: 'CZ'}
        stationIsPersisted.value = false
      },
      editStation: (id: string) => {
        const station = stations.find((s) => s.id === id)!
        stationWorkingCopy.value = {...station}
        stationIsPersisted.value = true
      },
      discardWorkingCopy: () => stationWorkingCopy.value = null,
      saveStation: () => {
        const station = stationWorkingCopy.value
        if (!station) {
          throw new Error("No station to be saved")
        }

        let savePromise = stationIsPersisted.value
          ? beastsStore.actions.updateStation(station)
          : beastsStore.actions.createNewStation(station)

        savePromise
          .then(() => {
            stationWorkingCopy.value = null
          })
          .catch((error) => console.error(error))
      },
      deleteId,
      deleteStation: (id: string) => {
        if (deleteId.value !== id) {
          deleteId.value = id;
          return
        }
        beastsStore.actions.deleteStation(id)
      }
    }
  },
})
